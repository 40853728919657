import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [~5],
		"/admin": [~6,[2]],
		"/admin/courses": [~7,[2]],
		"/admin/courses/[course_id]": [~8,[2]],
		"/admin/courses/[course_id]/tests": [~9,[2]],
		"/admin/courses/[course_id]/tests/[test_id]": [~10,[2]],
		"/admin/courses/[course_id]/tests/[test_id]/takings": [~11,[2]],
		"/admin/forms": [~12,[2]],
		"/admin/forms/answers": [~13,[2]],
		"/admin/forms/answers/[test_id]": [~14,[2]],
		"/admin/forms/tests": [~15,[2]],
		"/admin/forms/tests/[test_id]": [~16,[2]],
		"/login": [~17,[3]],
		"/tests/taking/[id]": [~18,[4]],
		"/tests/[anchor]": [~19,[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';